export enum MainMenuEnum {
  NewProject = 'new-project',
  Projects = 'projects',
  DailyTasks = 'daily-tasks',
  Schedule = 'schedule',
  Scheduler = 'scheduler',
  Billing = 'billing',
  Settings = 'settings',
  Notification = 'notification',
  Clients = 'clients',
  Employees = 'employees',
  Zones = 'zones',
  Offers = 'offers',
  Coupons = 'coupons'
}

export enum MainMenuRouteEnum {
  NewProject = '/new-project',
  Projects = '/projects',
  DailyTasks = '/daily-tasks',
  Schedule = '/schedule',
  Billing = '/billing',
  Settings = '/settings',
  Notification = '/notification',
  Zones = '/settings/zones',
  Clients = '/clients',
  Employees = '/employees',
  Offers = '/settings/offers',
  Scheduler = '/scheduler',
  Coupons = '/settings/coupons',
}
