import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';

import { MainMenuModel } from '../../../../shared/models/navigation.model';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})
export class CommonHeaderComponent implements OnInit {
  @Input() public userProfile: any;
  @Input() public mainMenu: MainMenuModel[];
  @Input() public notifications: any[] = [];

  @Output() public logoutClicked: EventEmitter<any> = new EventEmitter();
  @Output() public showSideMenu: EventEmitter<any> = new EventEmitter();

  public sideMenuOpened = true;
  public showNotificationMenu = false;
  public respondedNotifications: any[] = [];
  public showUserDropdown = false;

  constructor(
    // private notificationDataService: NotificationDataService,
    // private notificationRequestService: NotificationHttpService,
    private router: Router,
    // private echoService: LaravelEchoService,
    // private cd: ChangeDetectorRef,
    ) {}

  public ngOnInit(): void {
    // this.notificationDataService.getRespondedNotifications().subscribe(data => {
    //   this.respondedNotifications = data;
    //   this.cd.markForCheck();
    // });

    // this.echoService.service.private('notifications.' + this.userProfile.user_account_id).
    // listen('NewNotification', data => {
    //   const notifications = this.respondedNotifications;
    //   notifications.unshift(data.notification);
    //   this.notificationDataService.setNotificationData(notifications);
    // });
  }

  public onUserDropDownToggle(): void {
    this.showUserDropdown = !this.showUserDropdown;
  }

  public onLogoutClick(): void {
    this.logoutClicked.emit();
  }

  public onShowSideMenu(): void {
    this.sideMenuOpened = !this.sideMenuOpened;
    this.showSideMenu.emit();
  }

  public countUnreadNotifications(): number {
    let returnCount = 0;

    if (this.notifications.length > 0) {
      this.notifications.forEach(notificationGroup => {
        notificationGroup.notifications.forEach(notification => {
          if (!notification.seen_at) {
            returnCount ++;
          }
        });
      });
    }

    return returnCount;
  }

  public markAsRead(i: number, j: number): void {
    this.notifications[i].notifications[j].seen_at = 'true';
    // this.notificationDataService.updateNotifications(this.notifications);
    // this.notificationRequestService.markNotificationRead(this.notifications[i].notifications[j].id);
  }

  public markAllAsRead(): void {
    this.notifications.forEach(notificationGroup => {
      notificationGroup.notifications.forEach(notification => {
        notification.seen_at = 'true';
      });
    });

    // this.notificationDataService.updateNotifications(this.notifications);
    // this.notificationRequestService.markAllAsRead();
  }

  public openNotification(i: number, j: number): void {
    const url = this.notifications[i].notifications[j].notification.link.replace(/^.*\/\/[^\/]+/, '');
    this.markAsRead(i, j);

    this.router.navigate([url]);
  }
}
