import { MainMenuEnum, MainMenuRouteEnum } from '../enums/navigation.enum';
import { MainMenuModel } from '../models/navigation.model';

export const getMainMenu = (): MainMenuModel[] => [
  {
    id: MainMenuEnum.Clients,
    name: 'Clients',
    isOpen: false,
    hasSubMenu: false,
    hasSidebar: false,
    route: MainMenuRouteEnum.Clients,
    show: true,
    icon: 'bi-file-earmark-plus',
  },
  {
    id: MainMenuEnum.Employees,
    name: 'Employees',
    isOpen: false,
    hasSubMenu: false,
    hasSidebar: false,
    route: MainMenuRouteEnum.Employees,
    show: true,
    icon: 'bi-file-earmark',
  },
  {
    id: MainMenuEnum.DailyTasks,
    name: 'Daily Tasks',
    isOpen: false,
    hasSubMenu: false,
    hasSidebar: false,
    route: MainMenuRouteEnum.DailyTasks,
    show: true,
    icon: 'bi-calendar-check',
  },
  {
    id: MainMenuEnum.Scheduler,
    name: 'Scheduler',
    isOpen: false,
    hasSubMenu: false,
    hasSidebar: false,
    route: MainMenuRouteEnum.Scheduler,
    show: true,
    icon: 'bi-card-checklist',
  },
  {
    id: MainMenuEnum.Billing,
    name: 'Billing',
    isOpen: false,
    hasSubMenu: false,
    hasSidebar: false,
    route: MainMenuRouteEnum.Billing,
    show: true,
    icon: 'bi-briefcase',
  },
  {
    id: MainMenuEnum.Zones,
    name: 'Zones',
    isOpen: false,
    hasSubMenu: false,
    hasSidebar: false,
    route: MainMenuRouteEnum.Zones,
    show: true,
    icon: 'bi-map',
  },
  {
    id: MainMenuEnum.Offers,
    name: 'Offers',
    isOpen: false,
    hasSubMenu: false,
    hasSidebar: false,
    route: MainMenuRouteEnum.Offers,
    show: true,
    icon: 'bi-box-seam',
  },
  {
    id: MainMenuEnum.Coupons,
    name: 'Coupons',
    isOpen: false,
    hasSubMenu: false,
    hasSidebar: false,
    route: MainMenuRouteEnum.Coupons,
    show: true,
    icon: 'bi-bell',
  },
];
