<div class="common-header">
  <div class="common-header__logo">
    <img
      class="common-header__logo-image"
      src="assets/images/logo.png"
      alt=""
      routerLink="/dashboard"
    />
  </div>

  <div class="common-header__menu">
    <div class="common-header__menu-item" *ngFor="let menuItem of mainMenu">
      <a
        routerLink="{{ menuItem.route }}"
        routerLinkActive="common-header__menu-link--active"
        class="common-header__menu-link"
        [class.common-header__menu-link--disabled]="menuItem.hasSubMenu"
        *ngIf="menuItem.show"
      >
      <i class="bi common-header__menu-link-icon" [class]="menuItem.icon"></i>
        <span>
          {{ menuItem.name }}
        </span>
      </a>
    </div>
  </div>

  <div class="common-header__profile">
    <ng-container>
      <div class="user-profile" (click)="onUserDropDownToggle()">
        <div class="user-profile__welcome">
          Welcome {{ userProfile?.first_name }} {{ userProfile?.last_name }}
        </div>

        <div class="user-profile__avatar">
            <img
            [src]="userProfile?.avatar?.url || 'assets/images/no-avatar.jpg'"
            alt="{{ userProfile?.first_name }}"
          />
          <!-- <i class="bi bi-person-circle" [style.font-size]="'2rem'" [style.color]="'#c1c9d4'"></i> -->
        </div>

        <div class="user-profile__dropdown-wrapper">
          <div
            class="user-profile__dropdown"
            [class.user-profile__dropdown--active]="showUserDropdown"
          >
            <div class="user-profile__account">
              <div class="user-profile__image-wrapper">
                <img
                  class="user-profile__image"
                  [src]="userProfile?.avatar?.url || 'assets/images/no-avatar.jpg'"
                  alt="{{ userProfile?.first_name }}"
                />
              </div>
              <div class="user-profile__info-wrapper">
                <h4>{{ userProfile?.first_name }} {{ userProfile?.last_name }}</h4>
                <a
                  class="user-profile__my-account"

                  >My Account</a
                >
              </div>
            </div>
            <div class="user-profile__logout-wrapper">
              <a class="user-profile__logout" (click)="onLogoutClick()">Logout</a>
            </div>
          </div>
        </div>

      </div>
      <div class="notification">
        <div
          class="notification__icon-wrapper"
          (click)="showNotificationMenu = !showNotificationMenu"
        >
        <i [style.font-size]="'1.8rem'" [style.color]="'#c1c9d4'" class="bi bi-chat-left-dots"></i>

          <!-- <img class="notification__icon" src="assets/images/icons/notification.png" /> -->
          <!-- <div class="notification__number" *ngIf="countUnreadNotifications() > 0">
            {{ countUnreadNotifications() }}
          </div> -->
        </div>
        <!-- <div
          class="notification-list-container"
          *ngIf="notifications.length > 0 && showNotificationMenu"
        >
          <div *ngFor="let notificationGroup of notifications; let i = index">
            <div class="notification-group-date-group">
              <div class="notification-group-date">{{ notificationGroup.date }}</div>
              <div
                class="notification-mark-all-read"
                *ngIf="i === 0 && countUnreadNotifications() > 0"
                (click)="markAllAsRead()"
              >
                Mark all as read</div>
            </div>
            <div
              class="notification-list-item"
              *ngFor="let notification of notificationGroup.notifications; let j = index"
            >
              <div class="notification-title">
                <div class="notification-subject">{{ notification.notification.subject }}</div>
                <div
                  class="notification-mark-read"
                  *ngIf="!notification.seen_at"
                >
                  <span (click)="markAsRead(i, j)"></span>
                </div>
              </div>
              <div
                class="notification-list-link"
                (click)="openNotification(i, j)"
              >
                {{ notification.notification.text }}
              </div>
            </div>
          </div>
          <div class="flex h-align-center notification-show-more">
            <a routerLink="/notifications/my-notifications">Show More</a>
          </div>
        </div> -->
      </div>

      <div class="notification text-white" [style.background]="'#209dd5'">
        More <i class="bi bi-caret-down ps-1"></i>
      </div>
    </ng-container>
    <div
      class="nav-toggler"
      [class.nav-toggler--opened]="sideMenuOpened"
      (click)="onShowSideMenu()"
    ></div>
  </div>
</div>
