import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthContainerComponent } from './components/auth-container/auth-container.component';
import { AuthHeaderComponent } from './components/auth-header/auth-header.component';

@NgModule({
  imports: [CommonModule, RouterModule, TranslateModule],
  declarations: [AuthContainerComponent, AuthHeaderComponent]
})
export class AuthLayoutModule {}
